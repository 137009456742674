<template>
    <div class="admin-page">
        <div class="title">
            <span>管理员</span>
        </div>
        <div class="search-div">
            <div>
                <el-button type="primary" size="small" @click="openAddDialog(false)">添加管理员</el-button>
                <el-button type="primary" size="small" @click="openAddMerchantDialog">添加商家管理员</el-button>
            </div>
            <div style="display: flex;">
               <el-input size='medium' v-model="search" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="adminList">
                <el-table-column prop="username" align="center" label="名称">
                </el-table-column>
                <el-table-column prop="name" align="center" label="真实姓名">
                </el-table-column>
                <el-table-column prop="phone" align="center" label="电话">
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                     <template slot-scope="scope">
                        {{scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="更新时间" align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                 <el-table-column prop="update_user_name" align="center" label="最后更新者">
                    <template slot-scope="scope">
                        {{scope.row.update_user_name || scope.row.create_user_name}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <span>
                         <el-button type="text" size="small" style="margin-right: 10px" @click="editAdminPassword(scope.row)">修改密码</el-button>
                         </span>
                         <span>
                             <el-popconfirm
                                confirmButtonText='确定'
                                cancelButtonText='取消'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteAdmin(scope.row.user_id)"
                                title="确定删除该管理员吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getAdminList">
            </el-pagination>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}管理员`" class="add-dialog" :close-on-click-modal="false"
             width="400px" :visible.sync="addAdminDialog">
            <el-form :model="adminData" status-icon :rules="rules" ref="adminForm" label-width="100px">
                <el-form-item label="用户名" prop="username">
                    <el-input size='medium' v-model="adminData.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input size='medium' type="password" v-model="adminData.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="真实姓名" prop="name">
                    <el-input size='medium' v-model="adminData.name"></el-input>
                </el-form-item>
                <el-form-item label="电话号码" prop="phone">
                    <el-input size='medium' v-model="adminData.phone"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addAdminDialog = false">取 消</el-button>
                <el-button type="primary" @click="addAdmin('adminForm')">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改密码" class="add-dialog" :close-on-click-modal="false"
             width="400px" :visible.sync="editPasswordDialog">
            <el-form status-icon label-width="80px">
                <el-form-item label="原密码">
                    <el-input size='medium' v-model="oldPassword" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input size='medium' v-model="newPassword" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editPasswordDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureChangePassword()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="添加商家管理员" class="add-dialog" :close-on-click-modal="false"
             width="400px" :visible.sync="addMerchantDialog">
            <el-form status-icon label-width="80px">
                <el-form-item label="用户名">
                    <el-input size='medium' v-model="merchantAdminData.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input size='medium' v-model="merchantAdminData.password" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addMerchantDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureAddMerchantAdmin()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'Admin',
    data () {
        return {
            // 快递业务列表
            adminList: [],
            page: 1,
            size: 10,
            total: 0,
            search: '',
            addAdminDialog: false,
            isEdit: false,

            // 管理员新增数据
            adminData: {
                username: '',
                password: '',
                name: '',
                phone: ''
            },

            editPasswordDialog: false,
            oldPassword: '',
            newPassword: '',

            addMerchantDialog: false,
            merchantAdminData: {
                username: '',
                password: ''
            },

            // 验证规则
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'change' }
                ],
                // password: [{ validator: validatePass, trigger: 'change' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 16, message: '长度在 6 到 16 个字符' }
                    // {
                    //     pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                    //     message: '密码须包含数字、字母两种元素'
                    // }
                ],
                name: [
                    { required: true, message: '请输入真实姓名', trigger: 'change' }
                ],
                phone: [
                    { required: true, message: '请输入电话号码', trigger: 'change' },
                    {
                        pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                        message: '请输入正确的手机号码'
                    }
                ]
            }

        }
    },
    mounted () {
        this.getAdminList()
    },
    methods: {
        // 获取电话分类列表
        getAdminList () {
            this.axios.post('/user/get/list', {
                page: this.page,
                size: this.size,
                search: this.search
            }).then((res) => {
                this.adminList = res.data.list
                this.total = res.data.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getAdminList()
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param editAdminData 为编辑时，管理员数据
         */
        openAddDialog (isEdit, editAdminData) {
            this.isEdit = isEdit
            this.addAdminDialog = true

            if (isEdit) {
                this.adminData = {
                    username: editAdminData.username,
                    password: '',
                    name: editAdminData.username,
                    phone: editAdminData.phone,
                    user_id: editAdminData.user_id
                }
            } else {
                this.adminData = {
                    username: '',
                    password: '',
                    name: '',
                    phone: ''
                }

                this.$nextTick(() => {
                    this.$refs.adminForm.clearValidate()
                })
            }
        },

        editAdminPassword (adminData) {
            this.editPasswordUserId = adminData.user_id
            this.editPasswordDialog = true
            this.oldPassword = ''
            this.newPassword = ''
        },

        sureChangePassword () {
            if (this.oldPassword === '') {
                this.$message.error('原密码不能为空！')
                return
            }
            if (this.newPassword === '') {
                this.$message.error('新密码不能为空！')
                return
            }
            this.axios.post('/user/set/password', {
                user_id: this.editPasswordUserId,
                password: this.oldPassword,
                new_password: this.newPassword
            }).then((res) => {
                this.$message.success('修改密码成功')
                this.editPasswordDialog = false
            })
        },

        openAddMerchantDialog () {
            this.addMerchantDialog = true
            this.merchantAdminData = {
                username: '',
                password: ''
            }
        },

        sureAddMerchantAdmin () {
            if (this.merchantAdminData.username === '') {
                this.$message.error('用户名不能为空！')
                return
            }
            if (this.merchantAdminData.password === '') {
                this.$message.error('密码不能为空！')
                return
            }
            this.axios.post('/user/add/merchant', {
                username: this.merchantAdminData.username,
                password: this.merchantAdminData.password
            }).then((res) => {
                this.$message.success('添加商家管理员成功')
                this.addMerchantDialog = false
            })
        },

        /**
         * 删除管理员
         * @param editAdminUserId 删除ID
         */
        deleteAdmin (editAdminUserId) {
            this.axios.post('/user/delete', {
                user_id: editAdminUserId
            }).then((res) => {
                if (res) {
                    this.getAdminList()
                    this.$message.success('删除成功')
                }
            })
        },

        addAdmin (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.post('/user/add', this.adminData).then((res) => {
                        console.log(res)
                        // 成功后清空输入框
                        this.adminData = {
                            username: '',
                            password: '',
                            name: '',
                            phome: ''
                        }

                        this.$refs.adminForm.resetFields()
                        this.$message.success('成功添加管理员')
                        this.addAdminDialog = false
                        this.getAdminList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .admin-page{
        .title{
            padding: 14px;
            margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

         .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }

        .add-dialog{
            .el-input,.el-select{
                width: 240px;
            }

            .el-dialog__body{
                padding: 10px 20px;
            }
        }
    }
</style>
